import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';

import CookieConsent from './components/web/CookieConsent/CookieConsent';
import i18n from './components/web/i18n/i18n';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Lazy-loaded components
const Header = lazy(() => import('./components/web/Header/Header'));
const Footer = lazy(() => import('./components/web/Footer/footer'));
const HomePage = lazy(() => import('./components/web/HomePage/HomePage'));
const News = lazy(() => import('./components/web/News/NewsPage'));
const NewsEdit = lazy(() => import('./components/web/News/News_edit'));
const AboutusPage = lazy(() => import('./components/web/Aboutus/AboutusPage'));
const AI4GoodPage = lazy(() => import('./components/web/AI4Good/AI4GoodPage'));
const PrivacyPolicy = lazy(() => import('./components/web/Privacy/privacy'));
const TermsOfUse = lazy(() => import('./components/web/TermsOfUse/TermsOfUse'));
const NotFound = lazy(() => import('./components/Others/NotFound'));

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Helmet>
          {/* Title and Meta Tags */}
          <title>TAIWAN.AI - Leveraging AI to Improve Lives and Benefit Society</title>
          <meta
            name="description"
            content="Taiwan.AI is dedicated to leveraging Artificial Intelligence for the improvement of people's lives and the benefit of society."
          />
          <meta
            name="keywords"
            content="Artificial Intelligence, AI, Society, Ethical AI, Taiwan AI, AI for Good, Social Impact, Technology for Humanity"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="author" content="Taiwan.AI" />
          <link rel="canonical" href="https://taiwan.ai/" />

          {/* Open Graph Metadata */}
          <meta property="og:title" content="TAIWAN.AI - Leveraging AI to Improve Lives" />
          <meta
            property="og:description"
            content="Discover how Taiwan.AI uses Artificial Intelligence to improve lives and benefit society through ethical and innovative solutions."
          />
          <meta property="og:url" content="https://taiwan.ai/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />

          {/* Security Headers */}
          <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline';" />
          <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
          <meta http-equiv="X-Frame-Options" content="DENY" />
          <meta http-equiv="X-Content-Type-Options" content="nosniff" />

          {/* Preconnect for Faster Resource Loading */}
          <link rel="preconnect" href="https://taiwan.ai" />
        </Helmet>

        {/* Cookie Consent */}
        <CookieConsent />

        <div className="App">
          {/* Suspense for Lazy Loading */}
          <Suspense fallback={<div className="loading">Loading...</div>}>
            <Header key={i18n.language} />
            <Routes>
              {/* Home Page */}
              <Route exact path="/" element={<HomePage />} />

              {/* News Pages */}
              <Route path="/news" element={<News key={i18n.language} />} />
              <Route path="/newsedit" element={<NewsEdit />} />

              {/* About Us */}
              <Route path="/aboutus" element={<AboutusPage />} />

              {/* AI for Good */}
              <Route path="/AI4Good" element={<AI4GoodPage key={i18n.language} />} />

              {/* Privacy Policy and Terms */}
              <Route path="/privacy-policy" element={<PrivacyPolicy key={i18n.language} />} />
              <Route path="/TermsOfUse" element={<TermsOfUse />} />

              {/* 404 Page */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer key={i18n.language} />
          </Suspense>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;